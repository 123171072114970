import { action, observable } from "mobx";

const reservedColors = [
  "#9FA475",
  "#5B51D5",
  "#EE683B",
  "#F1BA52",
  "#8E403A",
  "#605B8A",
  "#1197C4",
  "#0E45AD"
  /** "#8F8F8F" reserved for default theme*/
];

const timePerDay = 480; /** 8h */

const places = ["remote", "on-site"];

const workTypes = ["normal", "penalty", "extra"];

const defaultValues = {
  timesheet: {
    month: 2,
    year: 2023,
    title: "Mindquest - iOS Dev"
  },
  workPlan: [],
  holidays: []
};

const LIMIT_THEMES = 9;

export default class NewTimesheetStore {
  @observable themes;

  @observable workPlan;

  @observable periods;

  @observable selectedPeriods;

  @observable listOfTypes;

  @observable normalHours;

  @observable specialHours;

  @observable totalHours;

  @observable timesheetPopUps;

  @observable rangeDetails;

  @observable themeToDelete;

  @observable timesheet;

  @observable dayDetails;

  @observable conversation;

  @observable canSave;

  @observable notReady;

  @observable isEdited;

  @observable periodToStore;

  @observable themeSelected;

  constructor(initialState = defaultValues) {
    this.themes = initialState.themes || [{ name: "N/A", color: "#8F8F8F" }];
    this.LIMIT_THEMES = LIMIT_THEMES;
    this.holidays = initialState.holidays || [];
    this.workPlan = initialState.workPlan || [];
    this.periods = initialState.periods || [];
    this.selectedPeriods = initialState.selectedPeriods || [];
    this.listOfTypes = initialState.listOfTypes || [];
    this.totalHours = initialState.totalHours || 0;
    this.normalHours = initialState.normalHours || 0;
    this.specialHours = initialState.specialHours || 0;
    this.rangeDetails = initialState.rangeDetails || {};
    this.themeToDelete = initialState.themeToDelete || null;
    this.timesheet = initialState.timesheet || {};
    this.dayDetails = initialState.dayDetails || [];
    this.conversation = initialState.conversation || [];
    this.canSave = initialState.canSave || true;
    this.notReady = initialState.notReady || true;
    this.isEdited = initialState.isEdited || false;
    this.periodToStore = initialState.periodToStore || null;
    this.themeToPreselect = initialState.themeToPreselect || null;
    this.themeSelected = initialState.themeSelected || "N/A";
    this.timesheetPopUps = initialState.timesheetPopUps || {
      addTheme: false,
      deleteTheme: false,
      moreInformation: false,
      addMessage: false,
      deleteRange: false,
      addTimeManually: false,
      limitOfThemes: false,
      dayDetails: false,
      sendTimesheet: false,
      sendCandidateTimesheet: false,
      rejectTimesheet: false,
      validateTimesheet: false,
      timesheetValidatedSuccessfully: false,
      timesheetRejectedSuccessfully: false,
      timesheetSentSuccessfully: false,
      timesheetSavedSuccessfully: false,
      messageSentSuccessfully: false,
      alertHoliday: false,
      alertHolidayToConfirm: false
    };
    this.extractPeriods();
    this.extractListOfTypes();
    this.calculateNormalAndSpecialHours();
  }

  //////////////////////////////////////// themes //////////////////////////////////
  editable = role => {
    if (!this.timesheet) return false;
    return role == "CLIENT"
      ? ["pending"].includes(this.timesheet.status)
      : ["open", "rejected"].includes(this.timesheet.status);
  };

  @action
  addTheme = theme => {
    const array = this.themes;
    const item = array.find(
      item =>
        item.name
          .toLowerCase()
          .split(" ")
          .join("") ===
        theme.name
          .toLowerCase()
          .split(" ")
          .join("")
    );
    if (!item) {
      const usedColors = [];
      array.forEach(obj => usedColors.push(obj.color));
      const availableColors = reservedColors.filter(
        e => !usedColors.includes(e)
      );
      theme.color =
        availableColors[Math.floor(Math.random() * availableColors.length)];
      array.push(theme);
      this.themes = array;
      this.extractPeriods();
      return "success";
    }
    return "failure";
  };

  getSpeakerName = speakerID => {
    if (this.timesheet.candidate.id == speakerID)
      return this.timesheet.candidate.name;
    if (
      this.timesheet.approvingClient &&
      this.timesheet.approvingClient.id == speakerID
    )
      return this.timesheet.approvingClient.name;
    if (
      this.timesheet.approvingClient2 &&
      this.timesheet.approvingClient2.id == speakerID
    )
      return this.timesheet.approvingClient2.name;
    return "N/A";
  };

  @action
  initializeConversation = (conversation, loggedUserID) => {
    const formattedConversation = [];
    if (
      conversation &&
      conversation.messages &&
      conversation.messages.length > 0
    ) {
      const filteredArr = conversation.messages.map(item => ({
        ...item,
        details: item.details.filter(
          detail => !(detail.type === "purchaseOrder")
        )
      }));
      filteredArr.forEach(item => {
        var date = new Date(item.date);
        formattedConversation.push({
          details: item.details,
          type: item.action,
          date:
            date.getDate() +
            "/" +
            (date.getMonth() + 1) +
            "/" +
            date.getFullYear(),
          mine: item.speaker == loggedUserID,
          speakerName: this.getSpeakerName(item.speaker)
        });
      });
    }

    this.conversation = formattedConversation;
  };

  changeThemeToPreselect = val => {
    this.themeToPreselect = val;
    this.isEdited = true;
  };

  changeThemeSelected = val => {
    this.themeSelected = val;
  };

  @action
  deleteTheme = themeName => {
    const array = this.themes;
    const removeIndex = array.map(item => item.name).indexOf(themeName);
    if (removeIndex !== -1) {
      array.splice(removeIndex, 1);
      this.themes = array;
      this.deleteFromWorkPlan({}, themeName);
      return "success";
    }
    return "failure";
  };

  @action
  changeTheme = (oldName, newName) => {
    const array = this.themes;
    const changeIndex1 = array.map(item => item.name).indexOf(oldName);
    if (changeIndex1 !== -1) {
      const changeIndex2 = array
        .map(item => item.name.toLowerCase().replaceAll(/\s/g, ""))
        .indexOf(newName.toLowerCase().replaceAll(/\s/g, ""));
      if (changeIndex2 === -1) {
        array[changeIndex1].name = newName;
      } else {
        return false;
      }
      this.themes = array;
      this.updateWorkPlanWhenThemeNameIsUpdated(oldName, newName);
      this.isEdited = true;
      return true;
    }
    return false;
  };

  @action
  getThemeColor = themeName => {
    const array = this.themes;
    const item = array.find(item => item.name === themeName);
    return item.color;
  };

  @action
  openClosePopUps = (popUp, forceClose = false) => {
    const timesheetPopUps = this.timesheetPopUps;
    timesheetPopUps[popUp] = forceClose ? false : !timesheetPopUps[popUp];
    this.timesheetPopUps = timesheetPopUps;
  };

  //////////////////////////////////////// workPlan //////////////////////////////////

  @action
  updateWorkPlanWhenThemeNameIsUpdated = (oldThemeName, newThemeName) => {
    const array = this.workPlan;
    const newArray = [];
    array.map(obj => {
      if (obj.theme.name === oldThemeName) {
        const Index = array.map(item => item.theme.name).indexOf(newThemeName);
        obj.theme.name = newThemeName;
        if (Index !== -1) obj.theme.color = array[Index].theme.color;
      }

      newArray.push(obj);
    });
    this.workPlan = newArray;
    this.extractPeriods();
  };

  removeWeekendsAndHolidays = period => {
    const dates = [];
    const currentDate = new Date(period.start.getTime());
    const endDate = new Date(period.end.getTime());

    const holidays = this.holidays.map(holiday => {
      return holiday.day;
    });

    while (currentDate <= endDate) {
      if (period.start.getDate() != endDate.getDate()) {
        if (
          currentDate.getDay() == 0 ||
          currentDate.getDay() == 6 ||
          holidays.includes(currentDate.getDate())
        ) {
          this.timesheetPopUps.alertHoliday = true;
        } else {
          dates.push(currentDate.getDate());
        }
      } else {
        if (holidays.includes(currentDate.getDate())) {
          if (!this.periodToStore) {
            this.timesheetPopUps.alertHolidayToConfirm = true;
            this.periodToStore = { ...period, start: endDate };
          } else {
            dates.push(currentDate.getDate());
            this.periodToStore = null;
            this.timesheetPopUps.alertHolidayToConfirm = false;
          }
        } else {
          dates.push(currentDate.getDate());
        }
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  };

  @action
  addPeriod = period => {
    const {
      start,
      end,
      hours,
      minutes,
      themeName,
      type,
      description,
      place
    } = period;
    const array = this.workPlan;
    const color = this.getThemeColor(themeName || "");
    const days = this.removeWeekendsAndHolidays(period);
    days.forEach(day => {
      array.push({
        day: day,
        hours: hours,
        minutes: minutes,
        theme: { color, name: themeName || "" },
        type: type || "normal",
        description: description || "",
        place: place || "on-site"
      });
    });

    this.workPlan = array;
    this.extractPeriods();
    this.extractListOfTypes();
    this.calculateNormalAndSpecialHours();
    this.isEdited = true;
  };

  @action
  deletePeriod = (period, themeName) => {
    const array = this.periods;
    const themeIndex = array.findIndex(obj => obj.themeName === themeName);
    let periodToDelete = { start: null, end: null, type: null };
    if (themeIndex !== -1) {
      const result = array[themeIndex].ranges[period.index];
      if (result) periodToDelete = result;
      this.deleteFromWorkPlan(periodToDelete, themeName);
    }
  };

  @action
  editPeriod = (period, themeName) => {
    this.deletePeriod(period, themeName);
    this.addPeriod(period);
  };

  @action
  deleteFromWorkPlan = ({ start, end, type }, themeName) => {
    let array = this.workPlan;
    if (start && end && type)
      array = array.filter(
        obj =>
          !(
            obj.day <= end &&
            obj.day >= start &&
            obj.theme.name === themeName &&
            (type === obj.type ||
              (type === "special" && ["extra", "penalty"].includes(obj.type)))
          )
      );
    else array = array.filter(obj => !(obj.theme.name === themeName));
    this.workPlan = array;

    this.extractPeriods();
    this.extractListOfTypes();
    this.calculateNormalAndSpecialHours();
    this.isEdited = true;
  };
  /* delete all days from workPlan */
  @action
  deleteAllDays = () => {
    this.periods = [];
    this.workPlan = [];
    this.themes = [];
    this.extractPeriods();
    this.extractListOfTypes();
    this.calculateNormalAndSpecialHours();
    this.isEdited = true;
  };
  @action
  extractPeriods = () => {
    const array = this.workPlan;
    if (array) {
      const workPlan = array.map(item =>
        item.type === "normal"
          ? item
          : {
            ...item,
            type: "special"
          }
      );
      const sortedWorkPlan = workPlan.sort(
        (a, b) =>
          a.theme.name.localeCompare(b.theme.name) ||
          a.type.localeCompare(b.type) ||
          a.day - b.day
      );

      let periods = [
        {
          themeName: "N/A",
          themeColor: "#8F8F8F",
          ranges: []
        }
      ];
      const timesheetThemes = [
        {
          name: "N/A",
          color: "#8F8F8F"
        }
      ];
      if (sortedWorkPlan.length) {
        let end = sortedWorkPlan[0]?.day;
        let start = sortedWorkPlan[0]?.day;
        let lastInput = sortedWorkPlan[0];
        let total = 0;
        let periodFinished = false;
        let j = 0;
        for (let i = 0; i <= sortedWorkPlan.length; i++) {
          let input = sortedWorkPlan[i];
          if (
            lastInput &&
            input &&
            lastInput.theme.name === input.theme.name &&
            lastInput.type === input.type &&
            (input.day === lastInput.day + 1 || input.day === lastInput.day)
          ) {
            total += input.hours * 60 + input.minutes;
            end = input.day;
          } else {
            periodFinished = true;
          }

          if (periodFinished) {
            let index = periods.findIndex(
              element => element.themeName === lastInput.theme.name
            );

            if (index === -1) {
              periods.push({
                themeName: lastInput.theme.name,
                themeColor: lastInput.theme.color,
                ranges: []
              });
              timesheetThemes.push({
                name: lastInput.theme.name,
                color: lastInput.theme.color
              });
              index = periods.length - 1;
            }

            periods[index].ranges.push({
              start,
              end,
              total,
              type: lastInput.type === "normal" ? "normal" : "special",
              description: lastInput.description,
              index: j
            });
            j++;
            periodFinished = false;
            total = input?.hours * 60 + input?.minutes;
            end = input?.day;
            start = input?.day;
          }
          lastInput = input;
        }
      }
      this.themes.map(theme => {
        const theme_found = periods.find(element => {
          return element.themeName.trim() === theme.name.trim();
        });

        if (!theme_found && periods.length < this.LIMIT_THEMES) {
          periods.push({
            themeName: theme.name,
            themeColor: theme.color,
            ranges: []
          });
          timesheetThemes.push(theme);
        }
      });

      periods = periods.sort((a, b) => a.themeName.localeCompare(b.themeName));
      /** Put N/A theme in top of the list */
      const idxObj = periods.findIndex(object => {
        return object.themeName === "N/A";
      });
      const firstElement = periods[idxObj];
      periods.splice(idxObj, 1);
      periods.unshift(firstElement);
      /** Order ranges by start date */
      periods = periods.map(period => {
        period.ranges = period.ranges.sort((a, b) => a.start - b.start);
        return period;
      });
      this.themes = timesheetThemes;
      this.periods = periods.filter(el => el !== null && el !== undefined);
    }
  };

  @action
  extractListOfTypes = () => {
    let listOfTypes = [];
    workTypes.map(type => {
      places.map(place => {
        listOfTypes.push({
          type,
          place,
          total: 0
        });
      });
    });

    const workPlan = this.workPlan || [];

    workPlan.forEach(day => {
      listOfTypes = listOfTypes.map(dayType => {
        if (dayType.type == day.type && dayType.place == day.place) {
          dayType.total += day.hours * 60 + day.minutes;
        }
        return dayType;
      });
    });
    this.listOfTypes = listOfTypes;
  };

  @action
  addDescriptionToPeriod = (period, description, themeName) => {
    const array = this.workPlan;
    const newArray = [];
    array.map(obj => {
      if (
        obj.day >= period.start &&
        obj.day <= period.end &&
        obj.theme.name === themeName &&
        ((period.type === "normal" && obj.type === "normal") ||
          (period.type === "special" &&
            (obj.type === "penalty" || obj.type === "extra")))
      ) {
        obj.description = description;
      }
      newArray.push(obj);
    });

    this.workPlan = newArray;
    this.extractPeriods();
    this.isEdited = true;
  };
  //////////////////////////////////////// Totals //////////////////////////////////

  calculateNormalAndSpecialHours = () => {
    const array = this.workPlan;
    if (array && array.length) {
      const workPlan = array.map(item =>
        item.type === "normal"
          ? item
          : {
            ...item,
            type: "special"
          }
      );
      const groupByType = workPlan.reduce((group, item) => {
        const { type } = item;
        group[type] = group[type] ?? { total: 0 };
        group[type].total = group[type].total + item.hours * 60 + item.minutes;
        return group;
      }, {});

      this.normalHours = groupByType.normal ? groupByType.normal.total : 0;
      this.specialHours = groupByType.special ? groupByType.special.total : 0;
      this.totalHours = this.normalHours + this.specialHours;
    } else {
      this.normalHours = 0;
      this.specialHours = 0;
      this.totalHours = 0;
    }
    this.checkWorkplan();
  };

  @action
  checkWorkplan = () => {
    const workPlan = this.workPlan;
    const rules = [
      { statement: "MAX_TIME_PER_DAY", limit: 600 },
      { statement: "MAX_NORMAL_TIME_PER_DAY", limit: 580 },
      { statement: "MAX_SPECIAL_TIME_PER_DAY", limit: 580 },
      { statement: "MAX_THEMES_PER_DAY", limit: 5 }
    ];

    /** GET Day by day metrics */
    const stats = workPlan.reduce((acc, input) => {
      let time = input.hours * 60 + input.minutes;
      const index = acc.findIndex(e => e.day == input.day);
      if (index > -1) {
        const news = {
          day: acc[index].day,
          totalTime: acc[index].totalTime + time,
          normalTime:
            acc[index].normalTime + (input.type == "normal" ? time : 0),
          specialTime:
            acc[index].specialTime + (input.type != "normal" ? time : 0),
          extraTime: acc[index].extraTime + (input.type == "extra" ? time : 0),
          penaltyTime:
            acc[index].penaltyTime + (input.type == "penalty" ? time : 0),
          remoteTime:
            acc[index].remoteTime + (input.place == "remote" ? time : 0),
          onSiteTime:
            acc[index].onSiteTime + (input.place == "on-site" ? time : 0),
          themes: acc[index].themes
        };
        if (!acc[index].themes.includes(input.theme.name))
          news.themes.push(input.theme.name);
        acc[index] = news;
      } else {
        acc.push({
          day: input.day,
          totalTime: time,
          normalTime: input.type == "normal" ? time : 0,
          specialTime: input.type != "normal" ? time : 0,
          extraTime: input.type == "extra" ? time : 0,
          penaltyTime: input.type == "penalty" ? time : 0,
          remoteTime: input.place == "remote" ? time : 0,
          onSiteTime: input.place == "on-site" ? time : 0,
          themes: [input.theme.name]
        });
      }
      return acc;
    }, []);

    /** check days warnings */
    const warnings = [];
    stats.forEach(day => {
      if (day.totalTime > timePerDay || day.totalTime < timePerDay) {
        warnings.push({
          day: day.day,
          warning: "ABNORMAL_TIME_PER_DAY",
          critical: false,
          number: day.totalTime
        });
      }
      rules.forEach(rule => {
        switch (rule.statement) {
          case "MAX_TIME_PER_DAY":
            if (rule.limit && day.totalTime > rule.limit) {
              warnings.push({
                day: day.day,
                warning: "MAX_TIME_PER_DAY",
                critical: true,
                number: day.totalTime
              });
            }
            break;
          case "MAX_NORMAL_TIME_PER_DAY":
            if (rule.limit && day.normalTime > rule.limit) {
              warnings.push({
                day: day.day,
                warning: "MAX_NORMAL_TIME_PER_DAY",
                critical: true,
                number: day.normalTime
              });
            }
            break;
          case "MAX_SPECIAL_TIME_PER_DAY":
            if (rule.limit && day.specialTime > rule.limit) {
              warnings.push({
                day: day.day,
                warning: "MAX_SPECIAL_TIME_PER_DAY",
                critical: true,
                number: day.specialTime
              });
            }
            break;
          case "MAX_THEMES_PER_DAY":
            if (rule.limit && day.themes.length > rule.limit) {
              warnings.push({
                day: day.day,
                warning: "MAX_THEMES_PER_DAY",
                critical: true,
                number: day.themes.length
              });
            }
            break;
          default:
            break;
        }
      });
    });
    //this.canSave = warnings.findIndex(warning => warning.critical) == -1;
    this.warnings = warnings;
  };

  // ===========================================================================
  @action
  changePeriodToStore = periode => {
    this.periodToStore = periode;
  };

  @action
  changeIsEdited = isEdited => {
    this.isEdited = isEdited;
  };

  @action
  changeRangeDetails = details => {
    this.rangeDetails = details;
  };

  @action
  changeThemeToDelete = themeName => {
    this.themeToDelete = themeName;
  };

  @action
  mergeHours = hours => {
    const mergedObjects = {};
    hours.forEach(obj => {
      let type = obj.type == "normal" ? "normal" : "extra";
      if (!mergedObjects[obj.theme.name]) {
        mergedObjects[obj.theme.name] = {
          day: parseInt(obj.day, 10)
        };
        mergedObjects[obj.theme.name][type] = {
          ...obj
        };
      } else {
        if (mergedObjects[obj.theme.name][type]) {
          mergedObjects[obj.theme.name][type].hours =
            mergedObjects[obj.theme.name][type].hours + obj.hours;
          mergedObjects[obj.theme.name][type].minutes =
            mergedObjects[obj.theme.name][type].minutes + obj.minutes;
        } else {
          mergedObjects[obj.theme.name][type] = {
            ...obj
          };
        }
      }
    });
    return mergedObjects;
  };

  @action
  getDayDetails = day => {
    let res = null;
    if (day) {
      const array = this.workPlan;
      const dayDetails = array.filter(item => item.day === parseInt(day, 10));

      if (dayDetails && dayDetails.length > 0) {
        res = this.mergeHours(dayDetails);
        const arrayOfObject = [];
        Object.keys(res).forEach((key, index) => {
          arrayOfObject.push(res[key]);
        });
        this.dayDetails = arrayOfObject;
      } else this.dayDetails = [{ day: parseInt(day, 10) }];
    } else this.dayDetails = [];
    return res;
  };

  checkIfReadyToValidate = timesheet => {
    const currentDate = new Date();
    const lastDayOfTsMonth = new Date();
    lastDayOfTsMonth.setMonth(timesheet.month, 0);
    lastDayOfTsMonth.setFullYear(timesheet.year);
    lastDayOfTsMonth.setDate(lastDayOfTsMonth.getDate());

    // Check if employment type is not "Payroll"
    if (timesheet.employmentType !== "Payroll") {
      //Check if the last day is saturday so we substract 3 days to meet two working days before the end of the month which means "Wednesday"
      if (lastDayOfTsMonth.getDay() === 6) {
        lastDayOfTsMonth.setDate(lastDayOfTsMonth.getDate() - 3);
      } else if (
        /**
         * Check if the last day is sunday so we substract 3 days to meet two working days before the end of the month which means "Wednesday"
         * Check if the last day is monday so we substract 3 days to meet two working days before the end of the month which means "Thursday"
         * Check if the last day is tuesday so we substract 3 days to meet two working days before the end of the month which means "Friday"
         */
        lastDayOfTsMonth.getDay() === 0 ||
        lastDayOfTsMonth.getDay() === 1 ||
        lastDayOfTsMonth.getDay() === 2
      ) {
        lastDayOfTsMonth.setDate(lastDayOfTsMonth.getDate() - 4);
      } else {
        /**
         * Otherwise we simply apply the condition of two working days before the end of the month
         */
        lastDayOfTsMonth.setDate(lastDayOfTsMonth.getDate() - 2);
      }
      // For Payroll employment type:
    } else {
      //Check if the last day is sunday so we substract 9 days to meet two working days before the end of the month which means "Friday"
      if (lastDayOfTsMonth.getDate() === 0) {
        lastDayOfTsMonth.setDate(lastDayOfTsMonth.getDate() - 9);
      }
      //Check if the last day is monday so we substract 10 days to meet two working days before the end of the month which means "Friday"
      else if (lastDayOfTsMonth.getDay() === 1) {
        lastDayOfTsMonth.setDate(lastDayOfTsMonth.getDate() - 10);
      }
      //Otherwise we simply apply the condition of six working days before the end of the month
      else {
        lastDayOfTsMonth.setDate(lastDayOfTsMonth.getDate() - 8);
      }
    }
    // Check if the timesheet is ready for validation
    return lastDayOfTsMonth <= currentDate ||
      timesheet.validationAllowed === true
      ? true
      : false;
  };

  @action
  initializeWorkPlan = timesheet => {
    this.workPlan = timesheet.workPlan;
    this.themes = [{ name: "N/A", color: "#8F8F8F" }];
    this.holidays =
      timesheet.holidays && timesheet.holidays.holidays
        ? timesheet.holidays.holidays.map(holiday => {
          return { day: holiday.day, description: holiday.name };
        })
        : [];
    if (timesheet.themes)
      timesheet.themes.map(theme => {
        if (theme.name != "N/A") {
          const themeIndex = this.themes.findIndex(
            obj => obj.name === theme.name
          );
          if (themeIndex == -1) this.themes.push(theme);
        }
      });
    this.extractPeriods();
    this.extractListOfTypes();
    this.calculateNormalAndSpecialHours();

    this.notReady = !this.checkIfReadyToValidate(timesheet);
    this.timesheet = timesheet;
    this.isEdited = false;
  };

  @action
  deleteTimeRange = ({ themeName, day, type }) => {
    const newWorkPlan = this.workPlan.filter(
      obj => !(obj.day === day && obj.theme.name === themeName && obj.type === type)
    );

    this.workPlan = newWorkPlan;
    this.extractPeriods();
    this.extractListOfTypes();
    this.calculateNormalAndSpecialHours();
    this.getDayDetails(day);
    this.isEdited = true;
  };

  @action
  deleteDay = day => {
    const workPlan = this.workPlan;
    const filteredWorkPlan = workPlan.filter(d => d.day != day);
    this.workPlan = filteredWorkPlan;
    this.extractPeriods();
    this.extractListOfTypes();
    this.calculateNormalAndSpecialHours();
    this.isEdited = true;
  };
}
